<template>
  <div >
   
    <section class="slider">
      <div class="row" style="height:100vh;background-size:cover;background-image: url('/images/slider.jpeg');width:auto">
        <div class="col-12 " style="height:100vh;background-color:rgba(0,0,0,0.6)" >
          <div class="row h-100">
            <div class="col-12 d-flex m-auto p-3" style="height:50%">
              <img src="/images/logo-white.png" class="m-auto logotipo" alt="EG MASTER FLOORING" style="height:auto" />
            </div>
            <div class="col-md-6 mt-auto">
              <div class="p-4 text-start "  style="padding-left:10% !important">
                <h1 class="text-light"><b>For quality flooring repair</b></h1>
                <h1 class="text-light"><b>CALL NOW</b></h1>

                <a href="tel:7608312851" style="text-decoration:none">
                  <div class="row">
                    <div class="col-2 p-3 " style="background-color:#fff;letter-spacing:3px">
                      <img src="/images/phone.png" alt="call now" style="width:100%" />
                    </div> 
                    <div class="col-10 p-3 text-light text-center telefono" style="background-color:#A15805;">
                      <h1 style="letter-spacing:3px;">760-831-2851</h1>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container">
      <div class="row pt-5 pb-5">
        <div class="col-12">
          <h1 style="font-size:50px">CARPET <b>REPAIR</b></h1>
        </div>
        <div class="col-md-6 p-4">
          <div style="width:99%px;height:338px;margin:auto;overflow:hidden;background-image:url('/images/carpet1.jpeg');background-size:cover">
            
          </div>
        </div>
        <div class="col-md-6 p-4 text-start">
          <h5 class="text-uppercase" style="letter-spacing:1px"><b>Hello my name is Ernesto Gutierrez.</b></h5>
          <p class="text-start"> I’ve been Installing carpet for over 30 years all over the Coachella valley. 
          With the experience I’ve obtained, I am more than certain I can install or repair any of your carpet needs.
           Below is my number for any questions or free estimates. <br><b>Feel free to give me a call. Thank you </b></p>
        </div>
        <div class="p-4 text-start">
          <p class="text-start"> Serving Palm Springs, Indio, Rancho Mirage, Palm Desert, Indian Wells and the rest of the Coachella valley. 
 
 With over 30 years of experience, I can re-stretch carpet, patch carpet, install carpet, and fix any other carpet issues you may have. </p>
        </div>
      </div>
    </section>

    <section class=" p-5 ">
      <div class="row">
        <div class="col-md-6 p-3">
          <img src="/images/galery1.jpg" alt="egflooring" class="img-fluid" />
        </div>
        <div class="col-md-6 p-3">
          <img src="/images/gallery2.jpg" alt="egflooring" class="img-fluid" />
        </div>
        <div class="col-md-6 p-3">
          <img src="/images/gallery3.jpg" alt="egflooring" class="img-fluid" />
        </div>
        <div class="col-md-6 p-3">
          <img src="/images/gallery4.jpg" alt="egflooring" class="img-fluid" />
        </div>
        <div class="col-md-6 p-3">
          <img src="/images/gallery5.jpg" alt="egflooring" class="img-fluid" />
        </div>
        <div class="col-md-6 p-3">
          <img src="/images/gallery6.jpg" alt="egflooring" class="img-fluid" />
        </div>
        
      </div>
    </section>

    <section class="beige p-5 footer">
      <div class="row">
        <div class="col-md-8 texto">
          <h1 class="text-light">Looking for an estimate? Give me a call</h1>
          <h1 style="font-size:60px">I'ts FREE</h1>
        </div>
        <div class="col-md-4">
          <a href="tel:7608312851" class="btn btn-lg btn-outline-light">CALL NOW</a>
        </div>
      </div>
    </section>

    <section>
      <div class="row">
        <div class="col-12">
          
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
